import React from "react"
import GridBox from "../GridBox"
import LogoHeadlineTeaser from "./LogoHeadlineTeaser"
import VideoZoomIn from "../VideoZoomIn"
import GridElement from "../GridElement"
import MaskSlidInAnimation from "../../DefinitionComponents/Animation-Components/MaskSlideInAnimation"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import {
  Heading,
  Box,
  Container,
  Button,
  Flex,
  Spacer,
  background,
} from "@chakra-ui/react"

import pdf1 from "/static/files/1905-anamnesebogen-voltmann.pdf"

import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"
function TitleScrollHeader() {
  return (
    <GridBox>
      <GridElement zIndex="1">
        <VideoZoomIn
          videoSrc={"Short1-Master-002.mp4"}
          videoAlt="generic video description"
          wrapperHeight={"150vh"}
          cropFactor={10}
        ></VideoZoomIn>
      </GridElement>
      <GridElement zIndex="3">
        <Container variant="layoutContainer" mt={12} px={12}>
          <Box
            h={"100vh"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <MaskSlidInAnimation threshold={1} duration={1}>
              <Heading
                color={"white"}
                fontSize={{ lg: "lg", md: "lg", base: "lg" }}
                fontWeight="bold"
                w={"100%"}
                letterSpacing="tighter"
              >
                Willkommmen bei der JadeKlinik
              </Heading>
            </MaskSlidInAnimation>
            <FadeInAnimation
              duration={1}
              delay={0}
              initialY={-5}
              threshold={0.4}
            >
              <Heading
                color={"white"}
                fontSize={{ lg: "9xl", md: "5xl", base: "5xl" }}
                fontWeight="bold"
                w={"100%"}
                letterSpacing="tighter"
              >
                VeoMed.
              </Heading>
            </FadeInAnimation>

            <Flex mt={0} flexWrap="wrap">
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={16}
                  mt={4}
                  color={"white"}
                  fontSize={{ lg: "xl", md: "lg", base: "lg" }}
                  fontWeight="bold"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  Dr. Jann Voltmann und Kollegen
                  <br />
                  heissen Sie willkommen.
                </Heading>
              </MaskSlidInAnimation>
            </Flex>
            <Flex mt={0} flexWrap="wrap">
              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} link={"/jadeklinik"}>
                  <Button mt={4} variant="ghost" color="white">
                    Kiefer und Gesichtschirurgie
                  </Button>
                </RouterLink>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.6}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} link={"/aesthetics/"}>
                  <Button
                    ml={{ base: 0, md: 4 }}
                    mt={4}
                    variant="ghost"
                    color="white"
                  >
                    Ästhetik
                  </Button>
                </RouterLink>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.7}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} href={pdf1}>
                  <Button
                    ml={{ base: 0, md: 4 }}
                    mt={4}
                    backgroundColor="white"
                    variant="solid"
                    color="brand.tan.dark"
                    _hover={{
                      backgroundColor: "brand.tan.dark",
                      color: "white",
                    }}
                  >
                    Anamnesebogen PDF
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Flex>
          </Box>
        </Container>
      </GridElement>
    </GridBox>
  )
}

export default TitleScrollHeader
